import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/product',
        name: 'product',
        title: '产品与解决方案',
        component: () => import('../views/productsandprograms/product.vue')
    },
    {
        path: '/newspage',
        name: 'newspage',
        title: '新闻动态',
        component: () => import('../views/newsInformation/newspage.vue')
    },
    {
        path: '/newspageDetails',
        name: 'newspageDetails',
        title: '查看全文',
        isChild: 0,
        childPath: '/newspage',
        childTitle: '新闻动态',
        component: () => import('../views/newsInformation/newspageDetails.vue')
    },
    {
        path: '/recruitpage',
        name: 'recruitpage',
        title: '招贤纳士',
        component: () => import('../views/recruitwisemen/recruitpage.vue')
    },
    {
        path: '/recruitpageDetails',
        name: 'recruitpageDetails',
        title: '职位信息',
        isChild: 0,
        childPath: '/recruitpage',
        childTitle: '招贤纳士',
        component: () => import('../views/recruitwisemen/recruitpageDetails.vue')
    },
    {
        path: '/aboutheindex',
        name: 'aboutheindex',
        title: '关于百吉百',
        component: () => import('../views/abouthecompany/aboutheindex.vue')
    },
    {
        path: '/corporateculture',
        name: 'corporateculture',
        title: '企业文化',
        isChild: 0,
        childPath: '/aboutheindex',
        childTitle: '关于百吉百',
        component: () => import('../views/abouthecompany/corporateculture.vue')
    },
    {
        path: '/contactus',
        name: 'contactus',
        title: '联系我们',
        isChild: 0,
        childPath: '/aboutheindex',
        childTitle: '关于百吉百',
        component: () => import('../views/abouthecompany/contactus.vue')
    },
    {
        path: '/qualifications',
        name: 'qualifications',
        title: '资质荣誉',
        isChild: 0,
        childPath: '/aboutheindex',
        childTitle: '关于百吉百',
        component: () => import('../views/abouthecompany/qualifications.vue')
    },
    {
        path: '/servicesupport',
        name: 'servicesupport',
        title: '服务支持',
        component: () => import('../views/abouthecompany/servicesupport.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
