import { createStore } from 'vuex'
import {getSetting} from "@/api/baseInfo";

export default createStore({
  state: {
    baseInfo: {}
  },
  getters: {
  },
  mutations: {
    SET_BASE_INFO: (state, active) => {
      state.baseInfo = active
    },
  },
  actions: {
    GetBaseInfo({commit}) {
      return new Promise((resolve, reject) => {
        getSetting().then(response => {
          const data = response.data.data
          commit('SET_BASE_INFO', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {

  }
})
