<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
  <div id="components-back-top-demo-custom" @click="upTop" v-show="topShow">
    <a-back-top>
      <img :src="goTop" style="cursor: pointer;width: 50%;height: 50%" />
    </a-back-top>
  </div>
</template>

<script>
import goTop from '@/assets/images/goTop.png'
export default {
  name: "App",
  data () {
    return {
      screenWidth:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      goTop,
      topShow: false
    };
  },
  created () {
    this.lastTime = new Date().getTime();
    this.resizeFun();
  },
  mounted () {
    const that = this;
    window.onresize = () => {
      return (() => {
        (window.screenWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth),
          (that.screenWidth = window.screenWidth);
        this.resizeFun();
      })();
    };
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop >= 1200) {
        this.topShow = true
      } else {
        this.topShow = false
      }
    }, true)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
  watch: {
    screenWidth (val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    fn() {
      return document.getElementById('app')
    },
    upTop () {
      document.documentElement.scrollTop = 0
    },   // 设置html标签的字体大小自适应   为了使得rem单位自适应
    resizeFun () {
      const that = this;
      var htmlobj = document.getElementsByTagName("html")[0];
      htmlobj.style.fontSize = (that.screenWidth / 1920) * 100 + "px";
    }
  }
};
</script>
<style>
#components-back-top-demo-custom {
  position: fixed;
  bottom: 5%;
  right: 5%;
}
</style>
