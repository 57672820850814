import axios from 'axios'

// 创建一个 axios 实例
const service = axios.create({
    baseUrl: '/',
    timeout: 60000, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: {
        'Access-Control-Allow-Origin': '*',
        // 设置后端需要的传参类型
        'Content-Type': 'application/json;charset=utf-8',
        // 'token': 'token',
        'X-Requested-With': 'XMLHttpRequest'
    },
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        // 获取token
        // 在发送请求之前做些什么
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        const res = {}
        res.status = response.status
        res.data = response.data

        return res
    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        console.log(error)
        return Promise.reject(error)
    }
)

export default service
